<template>
  <div class="loader-block">
    <img src="@/assets/icons/searching.gif" alt="" class="h-78">
  </div>
</template>

<script>
import axios from 'axios';
import amplitude from 'amplitude-js';

export default {
  name: 'Verification',
  async created () {
    const event = 'reg_confirm';
    const eventProperties = {
      type: 'email'
    };
    amplitude.getInstance().logEvent(event, eventProperties);

    try {
      if (this.$route.params.signup_token) {
        let str = this.$route.params.code.replace('/\\/', '');
        str = str.replace('"', '');
        const body = {
          code: str,
          transport: 1,
          signup_token: this.$route.params.signup_token
        };
        await axios.post('/api/v3/signup/verification', body);
        this.$store.commit('setSignUpToken', this.$route.params.signup_token);
      } else {
        this.$store.commit('setCodeForChangePassword', this.$route.params.code);
      }
      this.$router.push('/create_password');
    } catch (error) {
      console.error(error);
      this.$router.push('/login');
    }
  }
};
</script>
